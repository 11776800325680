import axios from 'axios';

let apiToken: string | undefined;

const apiClient = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_AML_API_ENDPOINT
});

export const setToken = (token: string | undefined) => {
	apiToken = token;
	apiClient.interceptors.request.use(function (config) {
		if (apiToken) {
			config.headers.Authorization = `Bearer ${apiToken}`;
			config.headers.Accept = "application/json";
		}
		return config;
	});
}
export const removeToken = () => {
	apiToken = undefined;
}
export default apiClient;