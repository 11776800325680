// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import HistoryIcon from '@material-ui/icons/History';
import Person from '@material-ui/icons/Person';
import Business from '@material-ui/icons/Business';
import PolicyIcon from '@material-ui/icons/Policy';
import ArticleIcon from '@material-ui/icons/Article';
import DescriptionIcon from '@material-ui/icons/Description';
import LoopIcon from '@material-ui/icons/Loop';
import FolderOpen from '@material-ui/icons/FolderOpen';
import ChecklistIcon from '@material-ui/icons/PlaylistAddCheck';
import ListIcon from '@material-ui/icons/List';
import CompareArrowIcon from '@material-ui/icons/CompareArrows';
import AccountsIcon from '@material-ui/icons/AssignmentInd';
import MoneyIcon from '@material-ui/icons/AttachMoney';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SvgIconStyle from '../../components/SvgIconStyle';
import { List } from 'immutable';
import { UserInterface } from 'contexts/appContext';
import { BasicAML, BasicClientManagement, BasicSecurities } from 'utils/pendo_guides';
import HasPermission from 'services/permissionsService';

const getIcon = (name: string, path: string = '/static/icons/navbar/') => (
	<SvgIconStyle src={`${path}${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);
const ICONS = {
	accounts: <AccountsIcon />,
	user: getIcon('ic_user'),
	dashboard: getIcon('ic_dashboard'),
	developer: getIcon('ic_code', '/static/icons/'),
	appearance: getIcon('colorpicker', '/static/icons/editor/'),
	cases: <FolderOpen />,
	sheet: <DescriptionIcon />,
	// list: getIcon('list_unordered', '/static/icons/editor/'),
	amlSearch: <PolicyIcon />,
	audit: <HistoryIcon />,
	users: <SupervisorAccountIcon />,
	reports: <ArticleIcon />,
	list: <ListIcon />,
	transactions: <CompareArrowIcon />,
	results: <ChecklistIcon />,
	ind: <Person />,
	entity: <Business />,
	recurring_screenings: <LoopIcon />,
	fee_schedule: <MoneyIcon />,
};

const sidebarConfig = (user: UserInterface) => [
	{
		subheader: 'general',
		items: [
			{ title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
		]
	},
	{
		subheader: 'Customer +',
		hasSupport: true,
		pendoClass: BasicClientManagement,
		condition: () => user?.has_client_management
			&& HasPermission({ orPermissions: ["can:clientsManagementIndividualsView", "can:clientsManagementEntitiesView"] }),
		items: [
			{ title: 'individuals', path: PATH_DASHBOARD.general.ind_customers, icon: ICONS.ind, condition: () => HasPermission({ permissions: ["can:clientsManagementIndividualsView"] })},
			{ title: 'entities', path: PATH_DASHBOARD.general.entity_customers, icon: ICONS.entity, condition: () => HasPermission({ permissions: ["can:clientsManagementEntitiesView"] })},
			{ title: 'Onboarding Requests', path: PATH_DASHBOARD.general.onboarding_requests, icon: ICONS.list },
		]
	},
	{
		subheader: 'Accounts +',
		condition: () => user?.has_accounts,
		items: [
			{ title: 'Accounts', path: PATH_DASHBOARD.accounts.list, icon: ICONS.accounts },
		]
	},
	{
		subheader: 'AML +',
		hasSupport: true,
		pendoClass: BasicAML,
		condition: () => user?.has_aml && HasPermission({ orPermissions: ["can:amlManagementScreeningsView", "can:amlManagementCasesView", "can:amlManagementSearchView"] }),
		items: [
			{ title: 'Screening Results', path: PATH_DASHBOARD.general.results, icon: ICONS.results, condition: () => HasPermission({ permissions: ["can:amlManagementScreeningsView"] })},
			{ title: 'AML Cases', path: PATH_DASHBOARD.general.cases, icon: ICONS.cases, condition: () => HasPermission({ permissions: ["can:amlManagementCasesView"] })},
			{ title: 'AML Search', path: PATH_DASHBOARD.general.aml_search, icon: ICONS.amlSearch, condition: () => HasPermission({ permissions: ["can:amlManagementSearchView"] })},
		]
	},
	{
		subheader: 'Trade +',
		hasSupport: true,
		pendoClass: BasicSecurities,
		condition: () => user?.has_securities,
		items: [
			{ title: 'Securities', path: PATH_DASHBOARD.securities.list, icon: ICONS.list },
			{ title: 'Transactions', path: PATH_DASHBOARD.securities.transactions, icon: ICONS.transactions },
		]
	},
	{
		subheader: 'Reports',
		condition: () => HasPermission({ permissions: [], orPermissions: ["can:userReportsClientsReportRun", "can:userReportsAuditsReportRun"] }),
		items: [
			{
				title: 'Clients Report',
				path: PATH_DASHBOARD.reports.clientReport,
				icon: ICONS.reports,
				condition: () => HasPermission({ permissions: ["can:userReportsClientsReportRun"] }),
			},
			{
				title: 'Audits',
				path: PATH_DASHBOARD.reports.audits,
				icon: ICONS.audit,
				condition: () => HasPermission({ permissions: ["can:userReportsAuditsReportRun"] }),
			},
		]
	},
	{
		subheader: 'settings',
		condition: () =>  user?.has_accounts || user?.has_aml || HasPermission({ permissions: ["can:userManagementBasicRead"] }),
		items: [
			{
				title: 'User Management',
				path: PATH_DASHBOARD.settings.user_management,
				icon: ICONS.users,
				condition: () => HasPermission({ permissions: ["can:userManagementBasicRead"] }),
			},
			{
				title: 'Recurring Screening',
				path: PATH_DASHBOARD.settings.recurring_screenings,
				icon: ICONS.recurring_screenings,
				condition: () => user?.has_aml && HasPermission({ permissions: ["can:amlManagementRecurringScreeningsView"] }),
			},
			{
				title: 'Fee Schedules',
				path: PATH_DASHBOARD.settings.fee_schedules.list,
				icon: ICONS.fee_schedule,
				condition: () => user?.has_accounts,
			},
			{
				title: 'Forms',
				path: PATH_DASHBOARD.settings.forms,
				icon: ICONS.sheet,
				condition: () => user?.has_client_management,
			}
			//{ title: 'Developer Options', path: PATH_DASHBOARD.settings.developer, icon: ICONS.developer },
			//{ title: 'Appearance', path: PATH_DASHBOARD.settings.appearance, icon: ICONS.appearance },
		]
	},
];

export default sidebarConfig;
